@import "../public/assets/scss/theme/variables";
@import "../public/assets/fontawesome";
@import "../public/assets/scss/sanfrancisco";
@import "../public/assets/scss/arial-black";

.block-squared:before {
  content: "";
  display: block;
  padding-top: 100%;
  /* initial ratio of 1:1*/
}

.block-vertical-rect:before {
  content: "";
  display: block;
  padding-top: 132%;
  /* initial ratio of 1:1*/
}

.block-horizontal-rect:before {
  content: "";
  display: block;
  padding-top: 68%;
  /* initial ratio of 1:1*/
}


.fixed-corner-left {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent rgb(220, 53, 69) transparent transparent;
  transform: rotate(-90deg);

  &.telegram {
    border-color: transparent #0088cc transparent transparent;
  }
}


.fixed-corner-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent $silver transparent transparent;
  transform: rotate(0deg);

  &.telegram {
    border-color: transparent #0088cc transparent transparent;
  }
}


.fixed-corner-left .text {
  display:block;
  margin: 1px;
  width: 55px;
  color:#ffffff;
    //  -ms-transform: rotate(45deg);
    // -webkit-transform: rotate(45deg); 
    // -moz-transform: rotate(45deg);
    // -o-transform: rotate(45deg);
    // -sand-transform: rotate(45deg);
    transform: rotate(90deg);
}

.fixed-corner-right .text {
  display:block;
  margin: 1px;
  width: 55px;
  color:#000000;
    //  -ms-transform: rotate(0deg);
    // -webkit-transform: rotate(0deg); 
    // -moz-transform: rotate(0deg);
    // -o-transform: rotate(0deg);
    // -sand-transform: rotate(0deg);
    // transform: rotate(0deg);
}


.fixed-corner-left.telegram .text {
     -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg); 
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -sand-transform: rotate(90deg);
    transform: rotate(90deg);
}


.block-horizontal-flat:before {
  content: "";
  display: block;
  padding-top: 45%;
  /* initial ratio of 1:1*/
}

.cover-text-block {
  top: 0;
  vertical-align: middle;
  // transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 100%;
  // color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0), rgba(0, 0, 0, 1.0));
  // background: rgba(0, 0, 0, 0.5);

}

.cover-text-block-small {
  top: 0;
  vertical-align: middle;
  // transform: translateY(-50%);
  left: 0;
  height: 100%;
  width: 100%;
  // color: white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0),  rgba(0, 0, 0, 0), rgba(0, 0, 0, 1.0));
  // background: rgba(0, 0, 0, 0.5);

}


.rounded-xl {
  border-radius: 10px !important;
}

.rounded-xl-one {
  // border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
  top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.5 !important;
}

.text-strike {
  text-decoration: line-through;
}

.large-header {
  font-size: 1.7rem;
  font-weight: 500;
}
.standart-header {
  font-size: 1rem !important;
  // font-weight: 500;

  p {
    margin-bottom: 0;
  }

  a {
    color: #212529 !important;
  }
  
  &.text-white a {
    color: white !important;
  }

}

.short-line-height {
  line-height: 1.2rem;
}


.modal-header,
.modal-footer {
  padding-top: 0.1em !important;
  padding-bottom: 0.1em !important;
  border: 0;
}
.modal-footer {
  justify-content: initial;
}

.modal-title {
  font-weight: bold;
  // text-transform: uppercase !important;
}

.btn {
  // text-transform: initial !important;
  // font-size: 1.1rem !important;
}

.main-wrapper {
  height: 80vh;
}

.btn:disabled {
  cursor: default !important;
  opacity: 0.2;
}

.slick-dots li button:before {
  font-size: 1.5rem !important;
}

.url {
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.bg-yellow {
  background-color: yellow !important;
}

.bg-green {
  background-color: #83e3bd !important;
}

.bg-magenta {
  background-color: #f828fb !important;
}

.bg-grey {
  background-color: #eee !important;
}

.bg-dark {
  background-color: #bbb !important;
}

.bg-black {
  background-color: #000 !important;
}


.mobile-menu {
  bottom: 0px;
  left: 0;
  // margin-bottom: -3px;
  z-index: 99;
  background-color: white;
  width: 100%;
  text-align: center;
  .button-menu { 
    i {
      font-size: 20px;
    }
  }
  // box-shadow: 8px 0px 8px #000;
  // height: 75px;
  // color: white;
  .row > div {
    // font-size: 20px;
    a {
      padding-top: 10px;
      display: inline-block;
      position: relative;
      // color: white !important;
    }

    .ultra-small {
      font-size: 10px;
    }

    
    &.active-mobile-menu {
      a, small {
        color: rgb(220, 53, 69) !important;
      }
      color: rgb(220, 53, 69) !important;
    }
  }
}

.mobile-menu-space {
  height: 75px;
}

.btn {
  text-transform: initial !important;
}

.btn-solid, .btn-outline {
  border-radius: 100px !important;
  padding: 18px 18px !important;
}

.btn-rounded {
  border-radius: 100px !important;
}

.btn-dark {
  border-color: #000;
  background-color: #000;
}

.bg-silver {
  background-color: #f3f3f3 !important;
}

.text-silver {
  color: #f3f3f3 !important;
}

.text-black {
  color: #000000 !important;
}

.product-photos {
  .swiper-pagination-bullet {
    background-color: #aaa !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff !important;
  }
}

.faq-content {
  width: 100%;
  p {
    margin-bottom: 0;
  }

  a {
    color: black;
  }

  img {
    max-width: 100%;
  }
}

.index-content {
  width: 100%;
  p {
    margin-bottom: 0;
  }

  a {
    color: white;
  }
}

.fast-feedback {
  // height: 200px;

  .feedback-body {
    padding-bottom: 35px;
    // max-height: 200px;
    // text-overflow: ellipsis;
  }

  .swiper-pagination-bullet {
    background-color: #aaa !important;
    opacity: 1 !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #000 !important;
  }
}

.fullscreen-modal {
  .modal-dialog {
    margin: 0;
  }
  .modal-content {
    min-width: 100vw !important;
    min-height: 100vh !important;
  }
}

body {
  &.modal-open {
    /* Prevent scrolling of body */
    overflow-y: hidden !important;
  }
}

.modal-header .close {
  font-size: 3rem;
  font-weight: 200;
}

.standart-selector-button {
  height: 35px;
}

.partner-logos {
  position: relative;
  // height: 20vh;
  .partner {
    z-index: 10;
    position: sticky;
    img {
      width: 120px;
      height: 120px;
      // border: 1px solid #ddd;
      &.bordered {
        // border: 1px solid;
      }
    }
    &.medium img {
      width: 85px;
      height: 85px;
    }
    &.small img {
      width: 60px;
      height: 60px;
    }
    &.micro img {
      width: 35px;
      height: 35px;
    }
    &.nano img {
      width: 24px;
      height: 24px;
    }
    .icon {
      position: absolute;
      left: 0;
      width: 100%;
      vertical-align: middle;
      text-align: center;
      // line-height: 120px;
    }
    &.small .icon {
      // line-height: 85px;
    }

    &.micro .icon {
      // line-height: 35px;
    }

    &.second {
      z-index: 9;
      margin-left: -20px;
    }

    &.second.small {
      margin-left: -15px;
    }
  }
}

.favorites-list {
  -ms-overflow-style: none; // for Internet Explorer, Edge 
  scrollbar-width: none; // for Firefox 
}
.favorites-list::-webkit-scrollbar {
  display: none;
}

.player-list {
  -ms-overflow-style: none; // for Internet Explorer, Edge 
  scrollbar-width: none; // for Firefox 
}
.player-list::-webkit-scrollbar {
  display: none;
}

.player-text-1 {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.player-text-2 {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.player-text-3 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blinking {
  animation: blinker 1s linear infinite;
}

.popover {
  z-index: 1999;
}

@keyframes blinker {
  50% {
    opacity: 30%;
  }
}